import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "./blog-post.css"

const Date = (props) => (
  <div className="blogPostDate">
    {props.children}
  </div>
)

export default ({ data }) => {
  const post = data.markdownRemark
  const canonicalLink = `${data.site.siteMetadata.domain}${post.fields.slug}`
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <link rel="canonical" href={canonicalLink} />
      </Helmet>
      <div>
        <h2>{post.frontmatter.title}</h2>
        <Date>{post.frontmatter.date}</Date>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        domain
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
      }
    }
  }
`
